<template>
  <v-container fluid>
    <v-card tile elevation="0">
      <v-card-title>
        Übersicht über gescannte Artikel.
        <v-spacer />
        <v-btn @click.stop="exportCsv()" :disabled="orderLineItems && !orderLineItems.length" tile color="primary" elevation="10">Liste als CSV exportieren</v-btn>
      </v-card-title>
      <v-data-table
          v-if="orderLineItems"
          :items="orderLineItems"
          :headers="headers"
      >
        <template v-slot:item.scanned="{ item }">
          {{ item.scanned | moment('DD.MM.YYYY') }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    headers: [
      {
        text: "Bestellnummer",
        align: "start",
        sortable: true,
        value: "orderNumber"
      },
      {
        text: "Mitarbeiter",
        align: "start",
        sortable: true,
        value: "orderCustomerName"
      },
      {
        text: "Produkt",
        align: "start",
        sortable: true,
        value: "lineItemLabel"
      },
      {
        text: "Preis pro Stück",
        align: "end",
        sortable: true,
        value: "lineItemUnitPrice"
      },
      {
        text: "Genutzt am",
        align: "end",
        sortable: true,
        value: "scanned"
      }
    ]
  }),

  computed: {
    ...mapGetters("benefit", ["orderLineItems"])
  },

  created() {
    this.init();
  },

  methods: {
    ...mapActions("benefit", ["list"]),

    init() {
      this.getOrderLineItems()
    },

    getOrderLineItems() {
      this.list({
        associations: {
          lineItems: {
            associations: {
              scans: {}
            }
          },
        },
        filter: [
          {
            type: "multi",
            operator: "and",
            queries: [
              {
                type: "equals",
                field: "lineItems.scans.scanned",
                value: 1
              }
            ]
          }
        ],
        includes: {
          order: ["orderNumber", "orderDate", "orderCustomer", "lineItems"],
          order_customer: ["email", "firstName", "lastName", "company"],
          hs_scanned_extension: ["scanned", "createdAt"],
          order_line_item: ["orderId", "id", "label", "unitPrice", "quantity", "scans", "payload"],
        }
      })
    },

    exportCsv() {
      let csvContent = "data:text/csv;charset=utf-8,";

      csvContent += [
          Object.keys(this.orderLineItems[0]).join(";"),
          ...this.orderLineItems.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "benefit.csv");
      link.click();
    }
  }
}
</script>